import { Box, Breadcrumbs, Chip, Grid, Paper, StyledEngineProvider, Table, TableBody, TableCell,TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import styled from '@emotion/styled';
import JODeploymentRequests from './JODeploymentRequests';
import JOPulloutRequests from './JOPulloutRequests';
import ProcessDialog from '../Utils/ProcessDialog';
import useAuth from '../../hooks/UseAuth';
import { JobOrdersProvider } from '../../context/JobOrdersProvider';
import ApproveJobOrder from './ApproveJobOrder';
import JOServiceCall from './JOServiceCall';
import MoreOptionBtn from './MoreOptionBtn';
import EditJobOrder from './EditJobOrder';
import SetForBilling from './SetForBilling';
import InvoiceJobOrder from './InvoiceJobOrder';
import CloseJobOrderRequest from './CloseJobOrderRequest';
import WarehouseBranchTooltip from '../AssetRegistry/WarehouseBranchTooltip';

const StyledTable = styled(Table)(
    ({ theme }) => `
    td,th
    {
        border: none;
    }
    `,
  );

function JobOrderDetails() {
  const axios = AxiosCustomConfig();
  const {auth} = useAuth();
  const [doneUpdateRequest, setDoneUpdateRequest] = useState(false);
  const {jorOrderIdunique} = useParams();
  const [jobOrderDetails, setJobOrderDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    let ignore = false;

    const getRequestDetails  = async (ignore) => {
        try {
            setLoading(true);
            const options = {
                'method': 'POST',
                'url' : '/JobOrders/getJobRequestsDetails.json',
                'data': {'job_order_id': jorOrderIdunique}
              }
              const response = await axios(options);
              const data = response.data;

              if (!ignore) {
                setJobOrderDetails(data);
              }
        } catch (error) {
            setOpenMessageDialog(true);
            setMessage(` ${error.toJSON().message}. Network error.`);
        } finally {
            setLoading(false);
        }
    }


    getRequestDetails(ignore)
    return () => {
        ignore = true
    };
}, [jorOrderIdunique, doneUpdateRequest]);

function formatKey(key) {
  // Replace underscores with spaces and capitalize each word
  return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}
  return (
    <JobOrdersProvider>
    <Box mb={3}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          color="inherit"
          to="/jobOrders"
        >
          Job Order
        </Link>
        <Typography color="text.primary">Details</Typography>
      </Breadcrumbs>
    </Box>

    <Grid container spacing={1}>
      <Grid item xs={12} md={6} lg={8}>
        <Grid container direction='column' spacing={4}>
            <Grid item>
              <Paper elevation={1} style={{ height: '100%', padding: '8px', display: 'flex', gap: '8px'}}>
                {Number(auth.role) === 6 || Number(auth.role) === 0 ? (
                  <>
                    <EditJobOrder jobOrderDetails={jobOrderDetails} setDoneUpdateRequest={setDoneUpdateRequest} />
                    <InvoiceJobOrder jobOrderDetails={jobOrderDetails} setDoneUpdateRequest={setDoneUpdateRequest} />
                  </>
                ): ''}
                {Number(auth.role) === 3 || Number(auth.role) === 2 || Number(auth.role) === 0 ? (
                  <>
                    <CloseJobOrderRequest jobOrderDetails={jobOrderDetails} setDoneUpdateRequest={setDoneUpdateRequest} />
                    <SetForBilling jobOrderDetails={jobOrderDetails} setDoneUpdateRequest={setDoneUpdateRequest}/>
                    <ApproveJobOrder jobOrderDetails={jobOrderDetails} setDoneUpdateRequest={setDoneUpdateRequest}/>
                  </>
                ) : ''}
                {Number(auth.role) === 3 || Number(auth.role) === 2 || Number(auth.role) === 6 || Number(auth.role) === 0 ? (
                  <>
                    <MoreOptionBtn jobOrderDetails={jobOrderDetails} setDoneUpdateRequest={setDoneUpdateRequest}/>
                  </>
                ) : ''}
                
              </Paper>
            </Grid>
            <Grid item>
                <Paper elevation={1} style={{ height: '100%' }}>
                    <JODeploymentRequests jorOrderIdunique={jorOrderIdunique} setDoneUpdateRequest={setDoneUpdateRequest}/>
                </Paper>
            </Grid>

            <Grid item>
                <Paper elevation={1} style={{ height: '100%' }}>
                    <JOPulloutRequests jorOrderIdunique={jorOrderIdunique} setDoneUpdateRequest={setDoneUpdateRequest}/>
                </Paper>
            </Grid>

            <Grid item>
                <Paper elevation={1} style={{ height: '100%' }} >
                    <JOServiceCall jorOrderIdunique={jorOrderIdunique} setDoneUpdateRequest={setDoneUpdateRequest}/>
                </Paper>
            </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <Paper elevation={1} style={{ height: '100%' }}>
          <Typography p={2}>Job order details</Typography>
            <StyledTable sx={{ width: '100%' }} size="small">
                <TableHead>
                </TableHead>
                <TableBody>
                {Object.entries(jobOrderDetails)
                  .filter(([key, _]) => !key.startsWith('deployment') && key !== 'pullout' && key !== 'service_request' && key !== 'warehouse_unique_code' && key !== 'hauling_unique_code' && key !== 'warehouse_id' && key !== 'current_rate' && key !== 'rate_selected' && key !== 'old_rate')
                  .map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell><strong>{formatKey(key)}:</strong></TableCell>
                        {key === 'status' ? (
                          value === 0 ? <Chip label='For Approval' sx={{ backgroundColor: '#35A7CA' }} /> : 
                          value === 1 ? <Chip label='Processing' sx={{ backgroundColor: '#D7D6D4'}} />: 
                          value === 2 ? <Chip label='JO form for completion' sx={{backgroundColor: '#F8FD6A', color: '#000'}} />: 
                          value === 3 ? <Chip label='Delivered' sx={{ backgroundColor: '#3AD658' }} />: 
                          value === 4 ? <Chip label='For Billing'  sx={{ backgroundColor: '#2174AE', color: '#fff' }} />: 
                          value === 5 ? <Chip label='Invoiced' sx={{ backgroundColor: '#661B66', color: '#fff' }} />: 
                          value === 6 ? <Chip label='Cancelled' sx={{ backgroundColor: '#D2893C' }} />:
                          ''
                        ) : key === 'centigen_branch' ? (
                          <WarehouseBranchTooltip id={jobOrderDetails.warehouse_id} value={value} />
                        ): (
                            value
                        )}
                    </TableRow>
                ))}
                </TableBody>
            </StyledTable>

        </Paper>
      </Grid>
     
    </Grid>


    <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
            }}
          message={message}
        />
    </StyledEngineProvider>
    </JobOrdersProvider>
  )
}

export default JobOrderDetails