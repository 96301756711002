import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData, validateAlphaNumeric, validateSingleFileExtension, validateSingleFileSize } from '../GenericFunctions/GenericFunctions';
import styled from '@emotion/styled';
import { CloudUpload } from '@mui/icons-material';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

function AddWarehouseAudit({assetCode, setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [openAssets, setOpenAssets] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [openWarehouse, setOpenWarehouse] = useState(false);
  const [warehouseLoading, setWarehouseLoading] = useState(false)
  const [auditAsset, setAuditAsset] = useState({
    assetSelected:[],
    asset_code: '',
    asset_name: '',
    warehouseSelected: [], 
    warehouse_code: '', 
    warehouse_name:'',
    warehouse_unique_code:'',
    asset_no_image:'',
    asset_image:'',
  });

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (auditAsset.assetSelected.length === 0) {
        setOpenMessageDialog(true);
        setMessage('Please Select assets')
        return
    }

    if (auditAsset.warehouseSelected.length === 0) {
        setOpenMessageDialog(true);
        setMessage('Please Select Store name')
        return
    }

    if (!auditAsset.asset_no_image) {
      setOpenMessageDialog(true);
      setMessage('Please Select Asset number image')
      return
    }

    if (!auditAsset.asset_image) {
      setOpenMessageDialog(true);
      setMessage('Please Select Asset image')
      return
    }

    if (!validateSingleFileSize(auditAsset.asset_no_image)) {
      // Collect names of files that exceed the size limit
      setOpenMessageDialog(true);
      setMessage(`Error in asset no. image. The following file(s) exceed the 2 MB limit: ${auditAsset.asset_no_image.name}. Please upload smaller files.`);
      return false;
    }

    if (!validateSingleFileExtension(auditAsset.asset_no_image)) {
      setOpenMessageDialog(true);
      setMessage(`Error in asset no. image. The following file(s) are not image files: ${auditAsset.asset_no_image.name}. Please upload valid image files.`);
      return false;
    }

    if (!validateSingleFileSize(auditAsset.asset_image)) {
      // Collect names of files that exceed the size limit
      setOpenMessageDialog(true);
      setMessage(`Error in asset image. The following file(s) exceed the 2 MB limit: ${auditAsset.asset_image.name}. Please upload smaller files.`);
      return false;
    }

    if (!validateSingleFileExtension(auditAsset.asset_image)) {
      setOpenMessageDialog(true);
      setMessage(`Error in asset image. The following file(s) are not image files: ${auditAsset.asset_image.name}. Please upload valid image files.`);
      return false;
    }

    if (!validateSingleFileSize(auditAsset.branch_image)) {
      // Collect names of files that exceed the size limit
      setOpenMessageDialog(true);
      setMessage(`Error in branch image. The following file(s) exceed the 2 MB limit: ${auditAsset.branch_image.name}. Please upload smaller files.`);
      return false;
    }

    if (!validateSingleFileExtension(auditAsset.branch_image)) {
      setOpenMessageDialog(true);
      setMessage(`Error in branch image. The following file(s) are not image files: ${auditAsset.branch_image.name}. Please upload valid image files.`);
      return false;
    }
    
    if (!isValidSpecialCharacter(auditAsset.remarks) && auditAsset.remarks !== '') {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Remarks.')
      return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/WarehouseAudit/addAudit.json',
              data: objectToFormData(auditAsset),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  const getAssets = async (ignore) => {
    try {
        setLoading(true)
        const options = {
            method: 'POST',
            url: '/AssetsRegistry/getAssetsForTransferRequest.json',
            'data' : {'asset_code': assetCode}
        }

        const response = await axios(options);
        if (!ignore) {
          setAssets(response.data);
          setLoading(false)
        }
    } catch (error) {
      console.log('Error info: ', error);
    }
  }
  const getWarehouses = async (ignore) => {
    try {
        setWarehouseLoading(true)
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
            data: {warehouse_unique_code: auditAsset.warehouse_unique_code}
        }

        const response = await axios(options);
        if(!ignore){
          setWarehouses(response.data);
          setWarehouseLoading(false)
        }

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const resetForm = () => {
    setAuditAsset({
      assetSelected:[],
      asset_code: '',
      asset_name: '',
      warehouseSelected: [], 
      warehouse_code: '', 
      warehouse_name:'',
      warehouse_unique_code:'',
      asset_no_image:'',
      asset_image:'',
    });
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleAssetNoImageChange = (event) => {
    const file = event.target.files[0];
    setAuditAsset((prev) => ({...prev, asset_no_image: file}));
  };
  const handleAssetImageChange = (event) => {
    const file = event.target.files[0];
    setAuditAsset((prev) => ({...prev, asset_image: file}));
  };

  const hanldeAssetsChange = (e, selectValue) => {
      if (selectValue) {
        console.log(selectValue);
        return setAuditAsset((prevData) => ({ ...prevData, assetSelected: selectValue, asset_code: selectValue.asset_code, asset_name: selectValue.asset_name, warehouse_unique_code: selectValue.warehouse_unique_code}))
      }

      setAuditAsset((prevData) => ({ ...prevData, assetSelected: [], asset_code: '', asset_name:''}))
  }
  const hanldeWarehouseChange = (e, selectValue) => {
      if (selectValue) {
        return setAuditAsset((prevData) => ({ 
          ...prevData, 
          warehouseSelected: selectValue,
          warehouse_code: selectValue.warehouse_code,
          warehouse_name: selectValue.warehouse_name,
          warehouse_unique_code: selectValue.warehouse_unique_code,
        }))
      }

      setAuditAsset((prevData) => ({ 
        ...prevData, 
        warehouseSelected: [], 
        warehouse_code: '', 
        warehouse_name:'',
        warehouse_unique_code:'',
      }))
  }

  useEffect(()=>{
    let ignore = false;

    if (!openAssets) {
      setAssets([])
      return undefined
    }
    getAssets(ignore);

    return () => {
      ignore = true;
    }
  }, [openAssets]);

  useEffect(()=>{
    let ignore = false;

    if (!openWarehouse) {
      setWarehouses([])
      return undefined
    }
    getWarehouses(ignore);

    return () => {
      ignore = true;
    }
  }, [openWarehouse]);


  return (
    <div>
    <Button variant="contained" size="small" onClick={handleOpen}>Create Audit</Button>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Add Audit Asset</DialogTitle>
      <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} mt={1}>
              <FormControl fullWidth>
                <Autocomplete
                  id="combo-box-demo"
                  open={openAssets}
                  onOpen={() => {setOpenAssets(true);}}
                  onClose={() => {setOpenAssets(false);}}
                  loading={loading}
                  onChange={hanldeAssetsChange}
                  options={assets}
                  value={assets.label}
                  noOptionsText={'No Available Data'}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select asset*"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                open={openWarehouse}
                onOpen={() => {setOpenWarehouse(true);}}
                onClose={() => {setOpenWarehouse(false);}}
                loading={warehouseLoading}
                onChange={hanldeWarehouseChange}
                options={warehouses}
                value={warehouses.label}
                noOptionsText={'No Available Data'}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Warehouse*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {warehouseLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
            <Grid container item xs={12} alignItems="center" spacing={2} >
              <Grid item>
                <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                  Upload Asset no Image
                  <VisuallyHiddenInput type="file" accept="image/*" onChange={handleAssetNoImageChange}/>
                </Button>
              </Grid>
              <Grid item>
                {auditAsset.asset_no_image && (
                  <p>{auditAsset.asset_no_image.name}</p>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems="center" spacing={2} >
              <Grid item>
                <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                  Upload Asset Image
                  <VisuallyHiddenInput type="file" accept="image/*" onChange={handleAssetImageChange}/>
                </Button>
              </Grid>
              <Grid item>
                {auditAsset.asset_image && (
                  <p>{auditAsset.asset_image.name}</p>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
            <Typography>Remarks:</Typography>
              <Textarea 
                aria-label="minimum height" 
                minRows={3} 
                placeholder="Input remarks" 
                onChange={(e) => setAuditAsset((prevData) => ({ ...prevData, remarks: e.target.value }))}
              />
          </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AddWarehouseAudit