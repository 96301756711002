import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { RateSelectionModal } from '../Utils/RateSelectionModal';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);
function AddJobOrder({setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [jobOrders, setJobOrders] = useState({
    trip_date: new Date().toISOString().substr(0, 10),
    hauling_rates: [],
    warehouse: [],
    remarks: '',
    number_of_request: 0,
    gt_deployment: 0,
    gt_pullout: 0,
    gt_service_request: 0,
    mt_deployment: 0,
    mt_pullout: 0,
    mt_service_request: 0,
    fs_deployment: 0,
    fs_pullout: 0,
    fs_service_request: 0,
    others_deployment: 0,
    others_pullout: 0,
    others_service_request: 0,
  });

  const [openRateSelection, setOpenRateSelection] = useState(false);
  const [haulingRateSettings, setHaulingRateSettings] = useState({
    value: 0,
    function_name: 'hauling_rate_settings'
  });

  const [haulingRates, setHaulingRates] = useState([]);
  const [openHaulingRate, setOpenHaulingRate] = useState(false);
  const haulingLoading = openHaulingRate && haulingRates.length === 0;
  const [warehouses, setWarehouses] = useState([]);
  const [openWarehouse, setOpenWarehouse] = useState(false);
  const warehouseLoading = openWarehouse && warehouses.length === 0;

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateRequiredFields()) return;

    if (!isValidSpecialCharacter(jobOrders.remarks) && jobOrders.remarks !== '') {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in remarks.')
      return
    }
    
    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/JobOrders/addJobOrder.json',
              data: jobOrders,
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          console.log(open);
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  const resetForm = () => {
    setJobOrders({
      trip_date: new Date().toISOString().substr(0, 10),
      hauling_rates: [],
      warehouse: [],
      remarks: '',
      number_of_request: 0,
      gt_deployment: 0,
      gt_pullout: 0,
      gt_service_request: 0,
      mt_deployment: 0,
      mt_pullout: 0,
      mt_service_request: 0,
      fs_deployment: 0,
      fs_pullout: 0,
      fs_service_request: 0,
      others_deployment: 0,
      others_pullout: 0,
      others_service_request: 0,
    });
  };


  const validateRequiredFields = () => {
    const requiredFields = ['trip_date',  'hauling_rates', 'warehouse', 'number_of_request'];

    for (const field of requiredFields) {
      if (!jobOrders[field] || (Array.isArray(jobOrders[field]) && jobOrders[field].length === 0)) {
        setOpenMessageDialog(true);
        setMessage('All Fields with asterisk (*) are required');
        return false;
      }
    }

    return true;
  };

  const getHaulingRates = async (active) => {
    try {
        const options = {
            method: 'POST',
            url: '/HaulingRates/getHaulingRatesForAutocomplete.json',
        }
        const response = await axios(options);
        if (active) {
          setHaulingRates(response.data);
        }
        
    } catch (error) {
      console.log('Error info: ', error);
    }
  }
  const getWarehouses = async (active) => {
    try {
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
        }
        const response = await axios(options);
        if (active) {
          setWarehouses(response.data);
        }
        
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    const calculateTotal = () => {
      const fields = [
        'gt_deployment', 'gt_pullout', 'gt_service_request',
        'mt_deployment', 'mt_pullout', 'mt_service_request',
        'fs_deployment', 'fs_pullout', 'fs_service_request',
        'others_deployment', 'others_pullout', 'others_service_request'
      ];
  
      const total = fields.reduce((sum, field) => {
        const value = parseFloat(jobOrders[field]) || 0;
        return sum + value;
      }, 0);
  
      setJobOrders((prev) => ({ ...prev, number_of_request: total }));
    };
  
    calculateTotal();
  }, [jobOrders]);

  const getHaulingRateSettingsSelection = async (ignore) => {
      try {
        const options = {
            method: 'POST',
            url: '/GeneralSettings/getHaulingRateSettings.json',
            data: haulingRateSettings
        }
        const response = await axios(options);
        if (!ignore) {
          setHaulingRateSettings(response.data);
        }
  
      } catch (error) {
        console.log('Error info: ', error);
      }
    }
  
    useEffect(() => {
      let ignore = false;
  
      if (!haulingLoading) return undefined
      getHaulingRateSettingsSelection(ignore)
  
      return () => {
        ignore = true
      };
    }, [haulingLoading]);

  useEffect(() => {
    let active = true;

    if (!haulingLoading) {
      return undefined;
    }

    getHaulingRates(active);

    return () => {
      active = false;
    };

  }, [haulingLoading]);

  useEffect(() => {
    if (!openHaulingRate) {
      setHaulingRates([]);
    }
  }, [openHaulingRate]);

  useEffect(() => {
    let active = true;

    if (!warehouseLoading) {
      return undefined;
    }

    getWarehouses(active);

    return () => {
      active = false;
    };

  }, [warehouseLoading]);

  useEffect(() => {
    if (!openWarehouse) {
      setWarehouses([]);
    }
  }, [openWarehouse]);


  const hanldeHaulingRequestsChange = (e, selectValue) => {
    if (selectValue) {
      setJobOrders((prevAccount) => ({ ...prevAccount, hauling_rates: selectValue}));
      if (haulingRateSettings.value !== 0) {
        setOpenRateSelection(true)
      }
    }
  }

  const hanldeWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      setJobOrders((prevAccount) => ({ ...prevAccount, warehouse: selectValue}))
    } else {
      setJobOrders((prevAccount) => ({ ...prevAccount, warehouse: []}))
    }
  }

  const handleSelectionRate = (selectedRate) => {
    setJobOrders((prevData) => ({ ...prevData, hauling_rate: selectedRate}))
  }

  return (
    <div>
    <Button variant="contained" size="small" onClick={handleOpen}>Create Job order</Button>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Add New Job order</DialogTitle>
      <DialogContent>
          <Grid container spacing={2}>
          <Grid item xs={12} mt={1}>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            label="Trip Date*"
                            value={jobOrders.delivery_date}
                            onChange={(newValue) => setJobOrders({...jobOrders, trip_date: dayjs(newValue).format('YYYY-MM-DD')})}
                            minDate={dayjs()}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                      id="combo-box-demo"
                      open={openWarehouse}
                      onOpen={() => {setOpenWarehouse(true);}}
                      onClose={() => {setOpenWarehouse(false);}}
                      onChange={hanldeWarehouseChange}
                      options={warehouses}
                      value={warehouses.label}
                      loading={warehouseLoading}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Warhouse*"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {warehouseLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                <Typography variant="subtitle2" color="initial" sx={{ minWidth: '150px', whiteSpace: 'nowrap' }}>
                  General Trade: 
                </Typography>
                <TextField
                  name="gt_deployment"
                  label="Deployment"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(input) && input >= 0 && input <= 1000000) {
                            setJobOrders((prevAccount) => ({ ...prevAccount, gt_deployment: input }));
                          }
                      }
                  }}
                  value={jobOrders.gt_deployment}
                />
                <TextField
                  name="gt_pullout"
                  label="Pullout"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(input) && input >= 0 && input <= 1000000) {
                            setJobOrders((prevAccount) => ({ ...prevAccount, gt_pullout: input }));
                          }
                      }
                  }}
                  value={jobOrders.gt_pullout}
                />
                <TextField
                  name="gt_service_request"
                  label="Service Call"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(input) && input >= 0 && input <= 1000000) {
                            setJobOrders((prevAccount) => ({ ...prevAccount, gt_service_request: input }));
                          }
                      }
                  }}
                  value={jobOrders.gt_service_request}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                <Typography variant="subtitle2" color="initial" sx={{ minWidth: '150px', whiteSpace: 'nowrap' }}>
                  Modern Trade: 
                </Typography>
                <TextField
                  name="mt_deployment"
                  label="Deployment"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(input) && input >= 0 && input <= 1000000) {
                            setJobOrders((prevAccount) => ({ ...prevAccount, mt_deployment: input }));
                          }
                      }
                  }}
                  value={jobOrders.mt_deployment}
                />
                <TextField
                  name="mt_pullout"
                  label="Pullout"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(input) && input >= 0 && input <= 1000000) {
                            setJobOrders((prevAccount) => ({ ...prevAccount, mt_pullout: input }));
                          }
                      }
                  }}
                  value={jobOrders.mt_pullout}
                />
                <TextField
                  name="mt_service_request"
                  label="Service Call"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(input) && input >= 0 && input <= 1000000) {
                            setJobOrders((prevAccount) => ({ ...prevAccount, mt_service_request: input }));
                          }
                      }
                  }}
                  value={jobOrders.mt_service_request}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                <Typography variant="subtitle2" color="initial" sx={{ minWidth: '150px', whiteSpace: 'nowrap' }}>
                  Food Service: 
                </Typography>
                <TextField
                  name="fs_deployment"
                  label="Deployment"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(input) && input >= 0 && input <= 1000000) {
                            setJobOrders((prevAccount) => ({ ...prevAccount, fs_deployment: input }));
                          }
                      }
                  }}
                  value={jobOrders.fs_deployment}
                />
                <TextField
                  name="fs_pullout"
                  label="Pullout"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(input) && input >= 0 && input <= 1000000) {
                            setJobOrders((prevAccount) => ({ ...prevAccount, fs_pullout: input }));
                          }
                      }
                  }}
                  value={jobOrders.fs_pullout}
                />
                <TextField
                  name="fs_service_request"
                  label="Service Call"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(input) && input >= 0 && input <= 1000000) {
                            setJobOrders((prevAccount) => ({ ...prevAccount, fs_service_request: input }));
                          }
                      }
                  }}
                  value={jobOrders.fs_service_request}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                <Typography variant="subtitle2" color="initial" sx={{ minWidth: '150px', whiteSpace: 'nowrap' }}>
                  Others: 
                </Typography>
                <TextField
                  name="others_deployment"
                  label="Deployment"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(input) && input >= 0 && input <= 1000000) {
                            setJobOrders((prevAccount) => ({ ...prevAccount, others_deployment: input }));
                          }
                      }
                  }}
                  value={jobOrders.others_deployment}
                />
                <TextField
                  name="others_pullout"
                  label="Pullout"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(input) && input >= 0 && input <= 1000000) {
                            setJobOrders((prevAccount) => ({ ...prevAccount, others_pullout: input }));
                          }
                      }
                  }}
                  value={jobOrders.others_pullout}
                />
                <TextField
                  name="others_service_request"
                  label="Service Call"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(input) && input >= 0 && input <= 1000000) {
                            setJobOrders((prevAccount) => ({ ...prevAccount, others_service_request: input }));
                          }
                      }
                  }}
                  value={jobOrders.others_service_request}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="number_of_request"
                  label="No. of SN*"
                  fullWidth
                  variant="outlined"
                  value={jobOrders.number_of_request}
                  InputProps={{ readOnly: true }}
              />
              <Typography variant="subtitle2" color="initial">Number of SN in this trip</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                      id="combo-box-demo"
                      open={openHaulingRate}
                      onOpen={() => {setOpenHaulingRate(true);}}
                      onClose={() => {setOpenHaulingRate(false);}}
                      onChange={hanldeHaulingRequestsChange}
                      options={haulingRates}
                      value={haulingRates.label}
                      loading={haulingLoading}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Hauling Rate*"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {haulingLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography>Remarks:</Typography>
                <Textarea 
                  aria-label="minimum height" 
                  minRows={3} 
                  placeholder="Remarks" 
                  onChange={(e) => setJobOrders((prevAccount) => ({ ...prevAccount, remarks: e.target.value }))}
                />
              </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>


      <RateSelectionModal open={openRateSelection} onClose={() => setOpenRateSelection(false)} currentRate={jobOrders.hauling_rates.rate} oldRate={jobOrders.hauling_rates.old_rate} onConfirm={handleSelectionRate}/>
      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AddJobOrder