import { Autocomplete, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, Box, styled, Typography, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData, trimWhitespaces } from '../GenericFunctions/GenericFunctions';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import useAuth from '../../hooks/UseAuth';
import { CloudUpload } from '@mui/icons-material';
import { useTheme } from '@emotion/react';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function EditRequest({requestDetails, setOnEditOrDelete}) {
  const theme = useTheme();
  const {auth} = useAuth();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [editData, setEditData] = useState({
    expected_pullout_date: '',
    pullout_type: [],
    pullout_type_code: '',
    pullout_type_name: '',
    assets: [],
    asset_code: '',
    warehouse: [],
    warehouse_unique_code: '',
    warehouse_name: '',
    store_account: [],
    store_contact_no: '',
    store_contact_name: '',
    remarks: '',
    tapor_file: '',
  });
  const [pulloutTypes, setPulloutTypes] = useState([]);
  const [assets, setAssetTypes] = useState([]);
  const [warehouse, setWarehouses] = useState([]);
  const [stores, setStores] = useState([]);
  const [loadResources, setLoadResources] = useState(false);
  const [branchCode, setBranchCode] = useState({branch_code: ''});
  const [openWarehouse, setOpenWarehouse] = useState(false);
  const [loadingWarehouse, setLoadingWarehouse] = useState(false);
  const [openStores, setOpenStores] = useState(false);
  const [loadingStores, setLoadingStores] = useState(false);
  const handleOpen = async () => {
    setOpen(true)
    setLoadResources(true)

    const {
      expected_pullout_date, 
      pullout_type_code, 
      pullout_type_name, 
      asset_code, 
      asset_name, 
      warehouse_name, 
      warehouse_unique_code, 
      account_code, 
      account_name, 
      store_contact_no, 
      store_contact_person, 
      remarks, 
      branch_code,
      request_code,
      account_unique_code,
      distributor_company_id
    } = requestDetails
    setEditData((prevData) =>({
      ...prevData, 
      expected_pullout_date: expected_pullout_date,
      pullout_type: {'label': pullout_type_name, value: pullout_type_code},
      pullout_type_code: pullout_type_code,
      pullout_type_name: pullout_type_name,
      assets: {'label': asset_name, value: asset_code},
      asset_code: asset_code,
      warehouse: {'label': `${warehouse_name} - (${warehouse_unique_code})`, 'value': warehouse_unique_code,},
      warehouse_unique_code: warehouse_unique_code,
      warehouse_name: warehouse_name,
      store_account: {'label': `${account_code} - (${account_name})`, 'account_code': account_code},
      store_contact_no: store_contact_no,
      store_contact_name: store_contact_person,
      remarks: remarks,
      request_code: request_code,
      account_unique_code: account_unique_code,
      distributor_company_id: distributor_company_id,
    }));

    const branchCode = branch_code; // or any other relevant code you have
    await getStores(false, { branch_code: branchCode });

    // After getting the stores, you can set the store_account if needed (if not set already)
    setEditData((prevData) => {
      const firstStore = stores.length > 0 ? stores[0] : prevData.store_account; // or your logic for selecting a store
      console.log(firstStore);
      
      return { ...prevData, store_account: firstStore };
    });
  }

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateRequiredFields()) return

    if (editData.expected_pullout_date.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Please select expected pull out date.')
      return
    }


    if (!isValidSpecialCharacter(editData.store_contact_name) && editData.store_contact_name !== "") {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Store Contact Name.')
      return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/PulloutRequests/editRequest.json',
              data: objectToFormData(editData),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }


  const resetForm = () => {
    setEditData({
      expected_pullout_date: '',
      pullout_type: [],
      pullout_type_code: '',
      pullout_type_name: '',
      assets: [],
      asset_code: '',
      warehouse: [],
      warehouse_unique_code: '',
      warehouse_name: '',
      store_account: [],
      store_contact_no: '',
      store_contact_name: '',
      remarks: '',
      tapor_file: '',
    });
  };

  const getWarehouses = async (ignore) => {
    setLoadingWarehouse(true)
    try {
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
        }

        const response = await axios(options);

        if(!ignore) {
          setWarehouses(response.data);
        }
    setLoadingWarehouse(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getPulloutTypes = async (ignore) => {
    try {
        const options = {
            method: 'POST',
            url: '/PulloutTypes/getPulloutTypesForRequest.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setPulloutTypes(response.data);
        }
        

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getAssetsInStore = async (ignore) => {
    try {
        
        const options = {
            method: 'POST',
            url: '/StoreAssets/getAssetsInStore.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setAssetTypes(response.data);
        }
        

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getStores = async (ignore) => {
    try {
        setLoadingStores(true)
        const options = {
            method: 'POST',
            url: '/ApiAccounts/getStoresForRequestViaBranch.json',
            data: {unique_code : editData.account_unique_code}
        }

        const response = await axios(options);
        if (!ignore) {
          setStores(response.data);

          if (response.data.length > 0) {
            const selectedStore = response.data[0];
            
            setEditData((prevData) => ({
              ...prevData,
              store_account: selectedStore,
            }));
          }
        }
        setLoadingStores(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }


  const handlePulloutTypeChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevData) => ({ ...prevData, pullout_type: selectValue, pullout_type_code: selectValue.pull_out_type_code, pullout_type_name: selectValue.label}))
    }
  }

  const handleWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevData) => ({ ...prevData, warehouse: selectValue, warehouse_unique_code: selectValue.warehouse_unique_code, warehouse_name: selectValue.warehouse_name}))
    }
  }

  const handleAssetsChange = async (e, selectValue) => {
    if (selectValue) {
      setEditData((prevData) => ({ ...prevData, assets: selectValue, asset_code: selectValue.asset_code, account_unique_code: selectValue.account_unique_code}))

      await getStores(false, { branch_code: selectValue.branch_code });
    }
  }

  const handleStoreAccontsChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevData) => ({ ...prevData, store_account: selectValue, account_unique_code: selectValue.account_unique_code, distributor_company_id: selectValue.distributor_company_id}))
    }
  }

  const validateRequiredFields = () => {
    const requiredFields = ['expected_pullout_date', 'pullout_type', 'assets', 'warehouse', 'store_account', 'store_contact_name', 'store_contact_no'];

    for (const field of requiredFields) {
      if(field === 'store_contact_name') {
        const trimmedremarks = trimWhitespaces(editData[field])
        if (!trimmedremarks) {
          setOpenMessageDialog(true);
          setMessage('All Fields with asterisks (*) are required');
          return false;
        }
      }

      if (!editData[field] || (Array.isArray(editData[field]) && editData[field].length === 0)) {
        setOpenMessageDialog(true);
        setMessage('All Fields with asterisks (*) are required');
        return false;
      }
    }

    return true;
  };

  useEffect(()=>{
    let ignore = false;

    if (loadResources && open) {
      getPulloutTypes(ignore);
      getAssetsInStore(ignore);
    }

    return () => {
      ignore = true;
    }
  }, [loadResources, open, branchCode]);
  
  useEffect(()=>{
    let ignore = false;
  
    if (!openWarehouse) {
      return undefined
    }

    getWarehouses(ignore);

    return () => {
      ignore = true;
    }
  }, [openWarehouse]);

  useEffect(()=>{
    if (!openWarehouse) {
      setWarehouses([])
    }
  }, [openWarehouse]);

  useEffect(()=>{
    let ignore = false;

    if (!openStores) return undefined
    getStores(ignore);

    return () => {
      ignore = true;
    }
  }, [openStores]);

  const handleTAPORChange = (event) => {
    const file = event.target.files[0];
    setEditData((prev) => ({...prev, tapor_file: file}));
  };

  return (
    <div>
    <Button 
    disabled={(requestDetails.status_code !== 0) ? true : false}
      variant="outlined" 
      size="small" 
      sx={{color: theme.palette.secondary.dark}} 
      onClick={(e) => {handleOpen(e)}}>
      Edit
    </Button>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Add New Request</DialogTitle>
      <DialogContent>
        <Box sx={{marginBottom: 2 }}>
            <Chip label="Asset Details" size="small" color="secondary" />
        </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            label="Pull out Date*"
                            value={editData.expected_pullout_date ? dayjs(editData.expected_pullout_date) : null}
                            onChange={(newValue) => setEditData({...editData, expected_pullout_date: dayjs(newValue).format('YYYY-MM-DD')})}
                            minDate={dayjs()}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={handleAssetsChange}
                    options={assets}
                    value={editData.assets}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={(params) => <TextField {...params} label="Asset no.*" />}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={handlePulloutTypeChange}
                    options={pulloutTypes}
                    value={editData.pullout_type}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={(params) => <TextField {...params} label="Pullout Type*" />}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                    open={openWarehouse}
                    onOpen={() => {setOpenWarehouse(true);}}
                    onClose={() => {setOpenWarehouse(false);}}
                    onChange={handleWarehouseChange}
                    options={warehouse}
                    loading={loadingWarehouse}
                    value={editData.warehouse}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Centigen Warehouse*"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingWarehouse ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                </FormControl>
            </Grid>
          </Grid>
            <Box sx={{marginBottom: 2, marginTop: 2}}>
                <Chip label="Customer Details" size="small" color="secondary" />
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        onOpen={() => {setOpenStores(true);}}
                        onClose={() => {setOpenStores(false);}}
                        loading={loadingStores}
                        onChange={handleStoreAccontsChange}
                        options={stores}
                        value={editData.store_account}
                        getOptionLabel={(option) => option.label || ''}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        renderInput={(params) => <TextField {...params} label="Store name*" />}
                        />
                    </FormControl>
                </Grid>
                <Grid container item xs={12} alignItems="center" spacing={2} >
                  <Grid item>
                    <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                      Upload new TAPOR
                      <VisuallyHiddenInput type="file" accept="application/pdf, image/*" onChange={handleTAPORChange}/>
                    </Button>
                  </Grid>
                  <Grid item>
                    {editData.tapor_file && (
                      <p>{editData.tapor_file.name}</p>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                <TextField
                    name="name"
                    label="Store Contact Person*"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, store_contact_name: e.target.value }))}
                    value={editData.store_contact_name}
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                    name="name"
                    label="Store contact no.*"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow up to 11 digits
                      if (/^\d{0,11}$/.test(input)) {
                        setEditData((prevData) => ({ ...prevData, store_contact_no: input }));
                      }
                    }}
                    value={editData.store_contact_no}
                />
                </Grid>
                
            </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default EditRequest