import { Close, CloudUpload, Edit } from '@mui/icons-material';
import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, StyledEngineProvider, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import useAuth from '../../hooks/UseAuth';
import { validateFileExtensions, validateFileSizes, isValidSpecialCharacter } from '../GenericFunctions/GenericFunctions';
import ConfirmationDialog from '../Utils/ConfirmationDialog';
import { RateSelectionModal } from '../Utils/RateSelectionModal';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
function EditRepairRequest({ repairDetails, setonRejectOrApprove }) {
  const { auth } = useAuth();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [repairRequests, setRepairRequests] = useState({
    asset: null,
    asset_code: '',
    asset_name: '',
    servicesSelected: [],
    services: [],
    new_services: [],
    image_before_repair: [],
    is_major_repair: '',
    remarks: '',
    id: '',
    request_code: '',
    warehouse: [],
    warehouse_unique_code: '',
    warehouse_name: '',
    old_warehouse_unique_code: '',
    imageBeforeRepairArray: [],
    removedImages: [],
  });

  const [openAssets, setOpenAssets] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [openService, setOpenService] = useState(false);
  const [repairRates, setRepairRates] = useState([]);
  const [serviceLoading, setServiceLoading] = useState(false);
  const [warehouse, setWarehouses] = useState([]);
  const [openWarehouse, setOpenWarehouse] = useState(false);
  const [loadingWarehouse, setLoadingWarehouse] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [repairRateSettings, setRepairRateSettings] = useState({
    value: 0,
    function_name: 'repair_rate_settings'
  });
  const [openRateSelection, setOpenRateSelection] = useState(false);
  const [rateSelectionData, setRateSelectionData] = useState(null);

  const handleOpen = () => {
    setOpen(true)

    const { is_major_repair, asset_code, asset_name, remarks, id, request_code, warehouse_unique_code, warehouse_name, services, images_before_repair, services_array } = repairDetails;

    const servicesSelectedArray = services_array
    ? services_array.map((item) => ({
        label: item.service_name,
        repair_rate : item.service_name,
        selected_rate : item.selected_rate, // Add selected_rate here
        price : item.selected_rate, // Add selected_rate here
      }))
    : [];

    const imageBeforeRepairArray = images_before_repair
      ? images_before_repair.split(',').map(img => img.trim())
      : [];


    setRepairRequests((prevData) => ({
      ...prevData,
      is_major_repair: is_major_repair,
      remarks: remarks,
      asset_code: asset_code,
      old_asset_code: asset_code,
      asset_name: asset_name,
      id: id,
      request_code: request_code,
      old_warehouse_unique_code: warehouse_unique_code,
      warehouse_unique_code: warehouse_unique_code,
      warehouse_name: warehouse_name,
      asset: { 'label': asset_code, value: asset_code },
      warehouse: { 'label': warehouse_name, warehouse_unique_code: warehouse_unique_code },
      servicesSelected: servicesSelectedArray,
      services: services,
      imageBeforeRepairArray: imageBeforeRepairArray,
    }))
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const resetForm = () => {
    setRepairRequests({
      asset: [],
      asset_code: '',
      asset_name: '',
      servicesSelected: [],
      services: [],
      new_services: [],
      image_before_repair: [],
      is_major_repair: '',
      remarks: '',
      id: '',
      request_code: '',
      warehouse: [],
      warehouse_unique_code: '',
      warehouse_name: '',
      old_warehouse_unique_code: '',
      imageBeforeRepairArray: [],
      removedImages: [],
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (repairRequests.old_warehouse_unique_code !== repairRequests.warehouse_unique_code && repairRequests.asset_code === repairRequests.old_asset_code) {
      setOpenMessageDialog(true);
      setMessage('You need to update the asset for evaluation to correct centigen branch.');
      return false;
    }

    const { isValid, invalidFiles } = validateFileSizes(repairRequests.image_before_repair);
    const { isValidExtension, invalidFilesExtension } = validateFileExtensions(repairRequests.image_before_repair);


    if (!isValid) {
      // Collect names of files that exceed the size limit
      const fileNames = invalidFiles.map((file) => file.name).join(', ');
      setOpenMessageDialog(true);
      setMessage(`The following file(s) exceed the 2 MB limit: ${fileNames}. Please upload smaller files.`);
      return false;
    }

    if (!isValidExtension) {
      const invalidFileNames = invalidFilesExtension.map((file) => file.name).join(', ');
      setOpenMessageDialog(true);
      setMessage(`The following file(s) are not image files: ${invalidFileNames}. Please upload valid image files.`);
      return false;
    }

    if (!repairRequests.remarks) {
      setOpenMessageDialog(true);
      setMessage('All Fields with asterisk (*) are required');
      return false;
    }

    if (!isValidSpecialCharacter(repairRequests.remarks) && repairRequests.remarks !== '') {
      setOpenMessageDialog(true);
      setMessage('Error! Invalid special character in remarks.');
      return false;
    }

    if (repairRequests.servicesSelected.length === 0) {
      setOpenMessageDialog(true);
      setMessage('All Fields with asterisk (*) are required');
      return false;
    }

    setShowProgress(true);
    try {
      const formData = new FormData();
      if (repairRequests.image_before_repair.length > 0) {
        repairRequests.image_before_repair.forEach((file, index) => {
          formData.append(`image_before_repair_${index}`, file);
        });
      }

      // Add other fields to formData if needed
      formData.append('asset_code', repairRequests.asset_code);
      formData.append('asset_name', repairRequests.asset_name);
      formData.append('is_major_repair', repairRequests.is_major_repair);
      formData.append('remarks', repairRequests.remarks);
      formData.append('services', repairRequests.services);
      formData.append('id', repairRequests.id);
      formData.append('request_code', repairRequests.request_code);
      formData.append('warehouse_unique_code', repairRequests.warehouse_unique_code);
      formData.append('warehouse_name', repairRequests.warehouse_name);
      formData.append('new_services', repairRequests.new_services);
      formData.append('removed_images', repairRequests.removedImages);

      // ✅ Convert repairRatesSelected into JSON string before appending
      formData.append('services_selected', JSON.stringify(repairRequests.servicesSelected));

      const options = {
        method: 'POST',
        url: '/ShopRepairs/UpdateRepair.json',
        data: formData,
      }

      const response = await axios(options);
      setShowProgress(false);
      if (response.data.is_success) {
        resetForm();
        setOpen(false)
        setOpenMessageDialog(true);
        setMessage(response.data.message)
      } else {
        setShowProgress(false)
        setOpenMessageDialog(true)
        setMessage(response.data.message)
      }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  const getAssets = async (ignore) => {
    try {
      setLoading(true)
      const options = {
        method: 'POST',
        url: '/AssetsRegistry/getAssetsForRepairs.json',
      }
      const response = await axios(options);
      if (!ignore) {
        setAssets(response.data);
      }
      setLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getRepairRateSettingsSelection = async (ignore) => {
    try {
      const options = {
        method: 'POST',
        url: '/GeneralSettings/getRepairRateSettings.json',
        data: repairRateSettings
      }
      const response = await axios(options);
      if (!ignore) {
        setRepairRateSettings(response.data);
      }

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getRepairRates = async (ignore) => {
    try {
      setServiceLoading(true)
      const options = {
        method: 'POST',
        url: '/RepairRates/getRepairRatesForAutoComplete.json',
      }
      const response = await axios(options);
      if (!ignore) {
        setRepairRates(response.data);
      }
      setServiceLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false;

    if (!openService) return undefined
    getRepairRateSettingsSelection(ignore)
    getRepairRates(ignore)

    return () => {
      ignore = true
    };
  }, [openService]);

  useEffect(() => {
    let ignore = false;

    if (!openAssets) return undefined
    getAssets(ignore)

    return () => {
      ignore = true
    };
  }, [openAssets]);

  const hanldeAssetsChange = (e, selectValue) => {
    if (selectValue) {
      return setRepairRequests(
        (prevAccount) => ({ ...prevAccount, asset: selectValue, asset_code: selectValue.asset_code, asset_name: selectValue.asset_name })
      )
    }

    setRepairRequests((prevAccount) => ({ ...prevAccount, asset: [] }))
  }

  const hanldeServicesChange = async (e, selectValue) => {
    if (!selectValue) return;
  
    const previousSelected = repairRequests.servicesSelected || [];
    const repairRates = selectValue.map((item) => item.repair_rate);
  
    // Update State Immediately with Selected Items
    setRepairRequests((prev) => ({
      ...prev,
      servicesSelected: selectValue,
      repairRates: repairRates,
    }));
  
    if (repairRateSettings.value === 0) return;
  
    const newSelected = selectValue.filter(
      (item) => !previousSelected.some((prev) => prev.repair_rate === item.repair_rate)
    );
  
    if (newSelected.length === 0) return;
  
    let selectedItems = [...previousSelected];
  
    for (const item of newSelected) {
      try {
        const selectedRate = await new Promise((resolve, reject) => {
          setOpenRateSelection(true);
          setRateSelectionData({
            currentRate: item.price,
            oldRate: item.old_price,
            onConfirm: resolve,
            onCancel: reject, // Reject will handle the cancel case
          });
        });
  
        selectedItems.push({
          repair_rate: item.repair_rate,
          selected_rate: selectedRate,
        });
  
      } catch (error) {
        // Remove the last selected item and refresh `Autocomplete`
        const filteredSelected = selectValue.filter((s) => s.repair_rate !== item.repair_rate);
        
        setRepairRequests((prev) => ({
          ...prev,
          servicesSelected: filteredSelected,
        }));

        return;
      }
    }
  
    const uniqueSelected = selectedItems.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.repair_rate === value.repair_rate)
    );
  
    setRepairRequests((prev) => ({
      ...prev,
      servicesSelected: uniqueSelected,
    }));
  };
  

  const hanldeMajorRepair = (e) => {
    setRepairRequests((prevAccount) => ({ ...prevAccount, is_major_repair: e.target.value }))
  }

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setRepairRequests((prevAccount) => ({ ...prevAccount, image_before_repair: [...prevAccount.image_before_repair, ...files] }))
  };

  const handleWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      setRepairRequests((prevData) => ({ ...prevData, warehouse: selectValue, warehouse_unique_code: selectValue.warehouse_unique_code, warehouse_name: selectValue.warehouse_name }))
    }
  }

  const removeImage = (indexToRemove) => {
    // Create a new array excluding the image at the specified index
    const updatedImages = repairRequests.image_before_repair.filter((_, index) => index !== indexToRemove);

    // Update the state with the new array of images
    setRepairRequests(prevState => ({
      ...prevState,
      image_before_repair: updatedImages
    }));
  };


  const getWarehouses = async (ignore) => {
    setLoadingWarehouse(true)
    try {
      const options = {
        method: 'POST',
        url: '/Warehouses/getWarehousesForAutocomplete.json',
      }

      const response = await axios(options);

      if (!ignore) {
        setWarehouses(response.data);
      }
      setLoadingWarehouse(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false;

    if (!openWarehouse) {
      return undefined
    }

    getWarehouses(ignore);

    return () => {
      ignore = true;
    }
  }, [openWarehouse]);


  // Open the confirmation dialog before removing
  const handleRemoveClick = (index) => {
    setSelectedImageIndex(index);
    setOpenDialog(true);
  };

  // Confirm and remove the image
  const confirmRemove = () => {
    if (selectedImageIndex === null) return;

    setRepairRequests(prevState => {
      const removedImage = prevState.imageBeforeRepairArray[selectedImageIndex];
      const updatedImages = prevState.imageBeforeRepairArray.filter((_, index) => index !== selectedImageIndex);
      const updatedRemovedImages = [...prevState.removedImages, removedImage];

      return {
        ...prevState,
        imageBeforeRepairArray: updatedImages,
        removedImages: updatedRemovedImages
      };
    });

    setOpenDialog(false);
    setSelectedImageIndex(null);
  };

  return (
    <>
      <Button
        variant='contained'
        size="small"
        color='info'
        startIcon={<Edit />}
        disabled={repairDetails.status === 4 || repairDetails.status === 1 || repairDetails.status === 5 || repairDetails.status === 3 || repairDetails.status === 10 || repairDetails.status === 7 || repairDetails.status === 8 ? true : false}
        onClick={handleOpen}
      >Edit
      </Button>
      <Dialog
        open={open}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle>Update Repair request</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} pt={1}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  open={openWarehouse}
                  onOpen={() => { setOpenWarehouse(true); }}
                  onClose={() => { setOpenWarehouse(false); }}
                  onChange={handleWarehouseChange}
                  options={warehouse}
                  loading={loadingWarehouse}
                  value={repairRequests.warehouse}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Centigen Branch*"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingWarehouse ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  id="combo-box-demo"
                  open={openAssets}
                  onOpen={() => { setOpenAssets(true); }}
                  onClose={() => { setOpenAssets(false); }}
                  loading={loading}
                  onChange={hanldeAssetsChange}
                  options={assets}
                  value={repairRequests.asset}
                  noOptionsText={'No Available Data'}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Asset for Evaluation"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="is-major-repair">Major repair?</InputLabel>
                <Select
                  labelId="is-major-repair"
                  value={repairRequests.is_major_repair}
                  label="Select value"
                  onChange={hanldeMajorRepair}
                >
                  <MenuItem value={0}>No</MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  open={openService}
                  onOpen={() => { setOpenService(true); }}
                  onClose={() => { setOpenService(false); }}
                  onChange={hanldeServicesChange}
                  options={repairRates}
                  value={repairRequests.servicesSelected}
                  getOptionLabel={(option) => option.repair_rate || ''}
                  isOptionEqualToValue={(option, value) => option.repair_rate === value.repair_rate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Service*"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {serviceLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} mb={2}>
              <Typography mt={1}>Photo before repair:</Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUpload />}
              >
                Upload images
                <VisuallyHiddenInput
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </Button>
              {repairRequests.image_before_repair.length > 0 && (
                <>
                  <Typography mt={2}>Selected Image:</Typography>
                  <ul>
                    {repairRequests.image_before_repair.map((file, index) => (
                      <li key={index}>
                        {file.name}
                        <IconButton color='error' aria-label="remove" onClick={() => removeImage(index)}>
                          <Close />
                        </IconButton>
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {repairRequests.imageBeforeRepairArray.length > 0 && (
                <>
                  <Typography mt={2}>Old Image:</Typography>
                  <ul>
                    {repairRequests.imageBeforeRepairArray.map((file, index) => (
                      <li key={index}>
                        {file}
                        <IconButton color='error' aria-label="remove" onClick={() => handleRemoveClick(index)}>
                          <Close />
                        </IconButton>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography>Remarks*:</Typography>
              <Textarea
                aria-label="minimum height"
                minRows={3}
                placeholder="Input Remarks"
                onChange={(e) => setRepairRequests((prevAccount) => ({ ...prevAccount, remarks: e.target.value }))}
                value={repairRequests.remarks}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ paddingLeft: 3, paddingRight: 3, marginBottom: 2 }}>
          <FormControl fullWidth>
            <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      {openRateSelection && rateSelectionData && (
        <RateSelectionModal
          open={openRateSelection}
          onClose={() => {
            rateSelectionData.onCancel(); // Reject on cancel
            setOpenRateSelection(false);
          }}
          currentRate={rateSelectionData.currentRate}
          oldRate={rateSelectionData.oldRate}
          onConfirm={(selectedRate) => {
            rateSelectionData.onConfirm(selectedRate);
            setOpenRateSelection(false);
          }}
        />
      )}
      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..." />
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose={() => {
            setOpenMessageDialog(false)
            setonRejectOrApprove((prev) => !prev)
          }}
          message={message}
        />
        {/* Confirmation Dialog */}
        <ConfirmationDialog
          open={openDialog}
          message="Are you sure you want to remove this image?"
          handleClose={() => setOpenDialog(false)}
          handleConfirm={confirmRemove}
          type="warning"
        />
      </StyledEngineProvider>

    </>
  )
}

export default EditRepairRequest