import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, StyledEngineProvider, Tooltip} from '@mui/material';
import React, {useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';
import { LinkOff } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import useAssetDisposalContext from '../../hooks/UseAssetDisposalContext';

function AssetUnlink({requestCode}) {
  const {setDoneUpdate, doneUpdate} = useAssetDisposalContext();
  const axios = AxiosCustomConfig();
  const [open,setOpen] = useState(false)
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [assets, setAssets] = useState([]);
  const [assetsLoading, setassetsLoading] = useState(false);
  const [dataRequestsData, setdataRequestsData] = useState({
    asset_codes: [],
    distributor_company_id: '', 
    request_code: '', 
  });
  const [selectedAssets, setSelectedAssets] = useState([]);
  const allSelected = selectedAssets.length === assets.length;
  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    setdataRequestsData(
      (prevData) => ({ ...prevData, request_code: requestCode})
    )

  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
    setSelectedAssets([])
    setAssets([])
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedAssets.length === 0) {
        setOpenMessageDialog(true);
        setMessage('Please Select request to unlink.')
        return
    }
    
    setShowProgress(true);
    try {
        const options = {
          method: 'POST',
          url: '/AssetsDisposal/removeAssetLink.json',
          data: objectToFormData(dataRequestsData),
        }

        const response = await axios(options);
        const {is_success, message} = response.data;

        setShowProgress(false);

        if (is_success) {
          setDoneUpdate((prev) => !prev)
          setOpenMessageDialog(true);
          setMessage(message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. There's something wrong with the server.`);
    }
  }

  const resetForm = () => {
    setdataRequestsData({
      asset_codes: [],
      distributor_company_id: '', 
    });
  };

  const getAssets = async(ignore) => {
    try {
      setassetsLoading(true)
        const options = {
            method: 'POST',
            url: '/AssetsDisposal/getLinkAssets.json',
            data: {request_code: requestCode}
        }
        const response = await axios(options);
        
        if (!ignore) {
          setAssets(response.data);
        }
        setassetsLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false
    getAssets(ignore);
    return () => {
      ignore = true
    };
  }, [open, doneUpdate]);

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectedAssets(isChecked ? assets.map((row) => row.asset_code) : []);
  };

  const handleToggle = (asset_code) => {
    setSelectedAssets((prev) =>
      prev.includes(asset_code) ? prev.filter((code) => code !== asset_code) : [...prev, asset_code]
    );
  };
  
  useEffect(() => {
    setdataRequestsData((prevData) => ({
      ...prevData,
      asset_codes: selectedAssets, // Sync dataRequests with selectedAssets
    }));
  }, [selectedAssets]);

  return (
    <div>
    <Tooltip title="Unlink" arrow>
      <IconButton
        onClick={(e) => handleOpen(e)}
        size="small"
        sx={{
          width: 35,
          height: 35,
          borderRadius: 1,
          backgroundColor: 'primary.main',
          color: 'white',
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
        }}
      >
        <LinkOff />
      </IconButton>
    </Tooltip>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Unlink Record</DialogTitle>
      <DialogContent>
      <Grid container spacing={3} mt={1}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allSelected}
                  onChange={handleSelectAll}
                  color="primary"
                />
              }
              label="Select All"
            />
            {assets.map((row) => (
              <div key={row.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <Checkbox
                  checked={selectedAssets.includes(row.asset_code)}
                  onChange={() => handleToggle(row.asset_code)}
                  color="primary"
                />
                <Link to={`/asset/${row.asset_id}`} style={{ textDecoration: 'underline', color: 'blue', marginLeft: '8px' }}>
                  {row.asset_name}
                </Link>
              </div>
            ))}

          </Grid>
        </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => {
              setOpenMessageDialog(false)
              }}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AssetUnlink