import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, StyledEngineProvider } from '@mui/material';
import React, { useState } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';

function DeleteRepairRate({repairRateData, setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({id: ''});
  const [showProgress,setShowProgress] = useState(false)
  const [openMessageDialog, setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true)
    const {id} = repairRateData;
    setDeleteData({id});
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDelete = async (e) => {
    e.stopPropagation();
    
    setShowProgress(true)
    try {
      const options = {
        method: 'POST',
        url: '/RepairRates/deleteRepairRate.json',
        data: objectToFormData(deleteData),
      }

      const response = await axios(options);

      setShowProgress(false)

      if (response.data.is_success) {
        setOpenMessageDialog(true);
        setMessage(response.data.message)
        setOpen(false)
      } else {
        setShowProgress(false)
        setOpenMessageDialog(true)
        setMessage(response.data.message)
      }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true)
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  return (
    <div>
      <Button 
        variant="outlined" 
        size="small" 
        color="error" 
        onClick={(e) => {handleOpen(e)}}>
      Delete
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
      <DialogTitle>Delete Repair rate</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" align='center'>
            Are you sure you want to delete this Repair rate? <p> <strong> {repairRateData.repair_rate}</strong>  </p>
          </DialogContentText>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, paddingTop: 3}}>
            <FormControl fullWidth>
              <Button variant="outlined" size="small" onClick={handleClose}>Cancel</Button>
            </FormControl>
            <FormControl fullWidth>
              <Button variant="contained" size="small" color='error' autoFocus onClick={handleDelete}>Delete</Button>
            </FormControl>
        </DialogActions>
        </DialogContent>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
            setOnEditOrDelete((prev) => !prev)
            } }
          message={message}
        />
      </StyledEngineProvider>
    </div>
  )
}

export default DeleteRepairRate