import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, StyledEngineProvider, Tooltip} from '@mui/material';
import React, {useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';
import { LinkOff } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import useJobOrdersContext from '../../hooks/UseJobOrdersContext';

function JOPulloutRequestUnlink({jorOrderIdunique, setDoneUpdateRequest}) {
  const {setDoneUpdate} = useJobOrdersContext();
  const axios = AxiosCustomConfig();
  const [open,setOpen] = useState(false)
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [pulloutRequests, setpulloutRequests] = useState([]);
  const [pulloutRequestsData, setpulloutRequestsData] = useState({
    job_order_id: '',
    pullout_requests: [],
  });
  const [selectedRequests, setSelectedRequests] = useState([]);
  const allSelected = selectedRequests.length === pulloutRequests.length;
  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    setpulloutRequestsData(
      (prevData) => ({ ...prevData, job_order_id: jorOrderIdunique})
    )

  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedRequests.length === 0) {
        setOpenMessageDialog(true);
        setMessage('Please Select request to unlink.')
        return
    }
    
    setShowProgress(true);
    try {
        const options = {
          method: 'POST',
          url: '/JobOrders/removeJobOrderPulloutRequestsLink.json',
          data: objectToFormData(pulloutRequestsData),
        }

        const response = await axios(options);
        const {is_success, message} = response.data;

        setShowProgress(false);

        if (is_success) {
          setDoneUpdateRequest((prev) => !prev)
          setDoneUpdate((prev) => !prev)
          setOpenMessageDialog(true);
          setMessage(message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. There's something wrong with the server.`);
    }
  }

  const resetForm = () => {
    setpulloutRequestsData({
      job_order_id: '',
      pullout_requests: [],
    });
  };

  const getPulloutRequests = async (ignore) => {
    try {
        const options = {
            method: 'POST',
            url: '/JobOrders/getJobOrderPulloutRequestsLink.json',
            data: {job_order_id: jorOrderIdunique},
        }
        const response = await axios(options);
        if(!ignore) {
          setpulloutRequests(response.data);
        }
        
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false
    getPulloutRequests(ignore);
    return () => {
      ignore = true
    };
  }, [open]);

  const handleSelectAll = () => {
    setSelectedRequests(allSelected ? [] : pulloutRequests.map((request) => request.request_code));
  };

  const handleToggle = (request_code) => {
    setSelectedRequests((prev) =>
      prev.includes(request_code) ? prev.filter((code) => code !== request_code) : [...prev, request_code]
    );
  };

  useEffect(() => {
    setpulloutRequestsData((prevData) => ({
      ...prevData,
      pullout_requests: selectedRequests, // Sync deploymentRequests with selectedRequests
    }));
  }, [selectedRequests]);

  return (
    <div>
    <Tooltip title="Unlink" arrow>
      <IconButton
        onClick={(e) => handleOpen(e)}
        size="small"
        sx={{
          width: 35,
          height: 35,
          borderRadius: 1,
          backgroundColor: 'primary.main',
          color: 'white',
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
        }}
      >
        <LinkOff />
      </IconButton>
    </Tooltip>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Unlink Record</DialogTitle>
      <DialogContent>
      <Grid container spacing={3} mt={1}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allSelected}
                  onChange={handleSelectAll}
                  color="primary"
                />
              }
              label="Select All"
            />
            {pulloutRequests.map((request) => (
              <div key={request.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <Checkbox
                  checked={selectedRequests.includes(request.request_code)}
                  onChange={() => handleToggle(request.request_code)}
                  color="primary"
                />
                <Link to={`/pulloutRequest/${request.request_code}`} style={{ textDecoration: 'underline', color: 'blue', marginLeft: '8px' }}>
                  {request.request_code}
                </Link>
              </div>
            ))}

          </Grid>
        </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => {
              setOpenMessageDialog(false)
              setOpen(false)
              }}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default JOPulloutRequestUnlink