import { Avatar, Box, CircularProgress, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
import AddWarehouse from './AddWarehouse';
import EditWarehouse from './EditWarehouse';
import DeleteWarehouse from './DeleteWarehouse';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import useFetchColumns from '../../hooks/UseFetchColumns';
import useFilteredAndOrderedColumns from '../../hooks/UseFilteredAndOrderedColumns';
import ColumnSettingsButton from '../Utils/ColumnSettingsButton';
import CustomToolbar from '../Utils/CustomToolbar';
import useFetchPageLength from '../../hooks/UseFetchPageLength';
import CustomGlobalFooter from '../Utils/CustomGlobalFooter';
import useSortModel from '../../hooks/UseSortModel';

const Warehouse = () => {
  const axios = AxiosCustomConfig();
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [warhouse, setWarehouse] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const functionName = 'warehousesExport';
  const modelTable = 'Warehouses';
  const [onSaveColumns, setOnSaveColumns] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const { paginationModel, setPaginationModel, pageSizeOptions } = useFetchPageLength(functionName, setOpenMessageDialog, setMessage, onSaveColumns);

  const header_columns = [
    { field: 'code', headerName: 'Code', flex: 1, minWidth: 100, wrap: true },
    { field: 'name', headerName: 'Name',flex: 1, minWidth: 150, wrap: true  },
    { field: 'location', headerName: 'Address',flex: 1, minWidth: 150, wrap: true},
    { field: 'created', headerName: 'Created',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
    { field: 'modified', headerName: 'Modified',flex: 1, minWidth: 200,  
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 200,
      wrap: true,
      renderCell: (params) => (
        <>
          <EditWarehouse warehouseData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
          <DeleteWarehouse warehouseData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
        </>
      ),
    },
  ];

  useEffect(() => {
    const getAssetTypes = async () => {
      try {
        setLoading(true);
        const options = {
          'method': 'POST',
          'url' : '/Warehouses/getAllWarehouse.json',
        }
        const response = await axios(options);
        const data = response.data;

        setWarehouse(data)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      } finally {
        setLoading(false);
      }
    }

    getAssetTypes();
  }, [onEditOrDelete]);

  const columns = useFetchColumns(functionName, onSaveColumns, setOpenMessageDialog, setMessage);
  const filteredAndOrderedColumns = useFilteredAndOrderedColumns(columns, header_columns);
  const [sortModel, setSortModel] = useSortModel(columns);


  const handleClickAllData = async () => {
    if(!selectAll) {
      try {
        setLoading(true);
        const options = {
          method: 'POST',
          url: '/Warehouses/getAllwarehousesId.json',
        };
        const response = await axios(options);
        
        const allDataID = response.data.map((row) => row.id);

        setRowSelectionModel(allDataID);
        setSelectAll(true);
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(`${error.toJSON().message}. Please try again and make sure you have an internet connection.`);
      } finally {
        setLoading(false);
      }
    } else {
      setRowSelectionModel([]);
      setSelectAll(false);
    }
  }

  return (
    <>
    <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center'  }}>
      <Box sx={{ display: 'flex', gap: '10px' }}>
      <AddWarehouse setOnEditOrDelete={setOnEditOrDelete}/>
      </Box>
      
      {/* Spacer */}
      <Box sx={{ flexGrow: 1 }} />

      {/* Settings Icon and Menu */}
      <Box sx={{ position: 'relative' }}>
        <ColumnSettingsButton
          columns={columns}
          setOnSaveColumns={setOnSaveColumns}
          functionName={functionName}
          paginationModel={paginationModel}
        />
      </Box>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
            toolbar: () => <CustomToolbar 
                selectedRows={rowSelectionModel} 
                columns={columns}
                setOnSaveColumns={setOnSaveColumns}
                functionName={functionName}
                modelTable={modelTable}
                handleClickAllData={handleClickAllData}
                selectAll={selectAll}
              />,
              footer: () => <CustomGlobalFooter selectedRows={rowSelectionModel.length}/>
          }}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          disableRowSelectionOnClick
          loading={loading}
          rows= {warhouse}
          columns={filteredAndOrderedColumns}
          pageSizeOptions={pageSizeOptions}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          sortingOrder={['asc', 'desc']}
          getRowHeight={() => 'auto'}
          autoHeight
          keepNonExistentRowsSelected
          sx={{
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
              py: 1,
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '15px',
            },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
              py: '22px',
            },
            '& .MuiDataGrid-columnHeaderCheckbox': {
              opacity: 0.5, 
              pointerEvents: 'none',
              cursor: 'not-allowed'
            }
          }}
        />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default Warehouse