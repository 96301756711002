import { Box, Button, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import AddAccounts from './AddAccounts';
import EditAccounts from './EditAccounts';
import DeleteAccounts from './DeleteAccounts';
import MessageDialog from '../Utils/MessageDialog';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import CustomGlobalFooter from '../Utils/CustomGlobalFooter';
import { useTheme } from '@emotion/react';
import Add from './Add';
import AddForDistributor from './AddForDistributor';
import AddAccountsForDistributors from './AddAccountsForDistributors';
import useAuth from '../../hooks/UseAuth';
import ColumnSettingsButton from '../Utils/ColumnSettingsButton';
import useFetchPageLength from '../../hooks/UseFetchPageLength';
import useFetchColumns from '../../hooks/UseFetchColumns';
import useFilteredAndOrderedColumns from '../../hooks/UseFilteredAndOrderedColumns';
import CustomToolbar from '../Utils/CustomToolbar';

const Accounts = () => {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const functionName = 'customersExport';
  const modelTable = 'Accounts';
  const [onSaveColumns, setOnSaveColumns] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const { paginationModel, setPaginationModel, pageSizeOptions } = useFetchPageLength(functionName, setOpenMessageDialog, setMessage, onSaveColumns);
  const columns = useFetchColumns(functionName, onSaveColumns, setOpenMessageDialog, setMessage);
  const [sortModel, setSortModel] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const header_columns = [
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 250,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <EditAccounts accountsData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
          <DeleteAccounts accountsData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
          <Button 
            variant="outlined" 
            size="small" 
            sx={{color: theme.palette.secondary.dark, marginRight: '10px'}} 
            onClick={() => { window.location.href = `/customers/${params.row.unique_code}`} }
          >
          View
          </Button>
        </Box>
      ),
    },
    { field: 'customer_no', headerName: 'JDE - Syspro',flex: 1, minWidth: 150, wrap: true },
    { field: 'store_name', headerName: 'Store Name', flex: 1, minWidth: 150, wrap: true},
    { field: 'business_address', headerName: 'Bus. address', flex: 1, minWidth: 150, wrap: true},
    { field: 'residential_address', headerName: 'Res. address', flex: 1, minWidth: 150, wrap: true},
    { field: 'distributor_account', headerName: 'Distributor/ Account',flex: 1, minWidth: 250, wrap: true },
    { field: 'owner_mngr_buyer_name', headerName: 'Owner / Manager / Buyer\'s name',flex: 1, minWidth: 150, wrap: true },
    { field: 'contact', headerName: 'Contact No.',flex: 1, minWidth: 150, wrap: true },
    { field: 'region', headerName: 'Region',flex: 1, minWidth: 150, wrap: true },
    { field: 'channel', headerName: 'Channel',flex: 1, minWidth: 150, wrap: true },
    { field: 'terms', headerName: 'Terms',flex: 1, minWidth: 150, wrap: true },
    { field: 'discount', headerName: 'Discount',flex: 1, minWidth: 150, wrap: true },
    { field: 'days_cover', headerName: 'Days cover',flex: 1, minWidth: 150, wrap: true },
    { field: 'frequency', headerName: 'Frequency',flex: 1, minWidth: 150, wrap: true },
    { field: 'active', headerName: 'Active',flex: 1, minWidth: 150, wrap: true },
    { field: 'customer_chain_static', headerName: 'Customer Chain Static',flex: 1, minWidth: 150, wrap: true },
    { field: 'csid', headerName: 'CSID',flex: 1, minWidth: 150, wrap: true },
    { field: 'customer_type', headerName: 'Customer Type',flex: 1, minWidth: 150, wrap: true },
    { field: 'distibutor_sales_representative', headerName: 'Distributor Sales Representative',flex: 1, minWidth: 150, wrap: true },
    { field: 'date_added', headerName: 'Date Added',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }
    },
    { field: 'date_updated', headerName: 'Date Updated',flex: 1, minWidth: 150,  
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }
    },
  ];

  const {auth} = useAuth();

  useEffect(() => {
    const getAccounts = async (paginationModel, filterModel, sortModel) => {
      setLoading(true)
      try {
        const options = {
          'method': 'POST',
          'url' : '/ApiAccounts/getAccounts.json',
          'data': {
            page: paginationModel.page, 
            pageSize: paginationModel.pageSize,
            filters: filterModel.items,
            sort: sortModel,
          }
        }
        const response = await axios(options);
        const {data, count} = response.data;

        if (data) {
          setAccounts(data)
          setRowCount(count);
        }
        setLoading(false)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      }
    }

    getAccounts(paginationModel, filterModel, sortModel);
  }, [onEditOrDelete, paginationModel, filterModel, sortModel]);

  const filteredAndOrderedColumns = useFilteredAndOrderedColumns(columns, header_columns);

  const handleRowSelectionModelChange = async (newSelection) => {
    setRowSelectionModel(newSelection);
  };

  const handleFilterChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
  };

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  const handleClickAllData = async () => {
    if(!selectAll) {
      try {
        setLoading(true);
        const options = {
          method: 'POST',
          url: '/ApiAccounts/getAccountsIds.json',
          'data': {
            filters: filterModel.items,
            sort: sortModel,
          }
        };
        const response = await axios(options);
        const allAccounts = response.data.data.map((account) => account.id);
        console.log(allAccounts);
        
        setRowSelectionModel(allAccounts);
        setSelectAll(true);
        setLoading(false);
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(`${error.toJSON().message}. Please try again and make sure you have an internet connection.`);
      } finally {
        setLoading(false);
      }
    } else {
      setRowSelectionModel([]);
      setSelectAll(false);
    }
  }

  return (
    <>
    <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <Add setOnEditOrDelete={setOnEditOrDelete}/>
        <AddAccounts setOnEditOrDelete={setOnEditOrDelete}/>
        {Number(auth.role) === 0 ? (
        <>
          <AddForDistributor setOnEditOrDelete={setOnEditOrDelete}/>
          <AddAccountsForDistributors setOnEditOrDelete={setOnEditOrDelete}/>
        </>
        ) : ''}
      </Box>

      {/* Spacer */}
      <Box sx={{ flexGrow: 1 }} />

      {/* Settings Icon and Menu */}
      <Box sx={{ position: 'relative' }}>
        <ColumnSettingsButton
          columns={columns}
          setOnSaveColumns={setOnSaveColumns}
          functionName={functionName}
          paginationModel={paginationModel}
        />
      </Box>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        rows={accounts}
        columns={filteredAndOrderedColumns}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          toolbar: () => <CustomToolbar 
              selectedRows={rowSelectionModel} 
              columns={columns}
              setOnSaveColumns={setOnSaveColumns}
              functionName={functionName}
              modelTable={modelTable}
              handleClickAllData={handleClickAllData}
              selectAll={selectAll}
            />,
          footer: () => <CustomGlobalFooter selectedRows={rowSelectionModel.length} />
        }}
        checkboxSelection
        onRowSelectionModelChange={handleRowSelectionModelChange}
        rowSelectionModel={rowSelectionModel}
        loading={loading}
        pagination
        paginationMode="server"
        filterMode="server"
        sortingMode="server"
        rowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        filterModel={filterModel}
        onFilterModelChange={handleFilterChange}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        pageSizeOptions={pageSizeOptions}
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        autoHeight
        keepNonExistentRowsSelected
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '10px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '10px',
          },
          '& .MuiDataGrid-columnHeaderCheckbox': {
            opacity: 0.5, 
            pointerEvents: 'none',
            cursor: 'not-allowed'
          }
        }}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default Accounts