import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Warning as WarningIcon, Info as InfoIcon } from '@mui/icons-material';
import React from 'react';

function ConfirmationDialog({ open, message, handleClose, handleConfirm, type = 'info' }) {
  // Determine the icon and color based on the type
  const getIconDetails = () => {
    switch (type) {
      case 'warning':
        return { icon: <WarningIcon fontSize="large" color="warning" />, color: 'warning.main', title: 'Warning' };
      default:
        return { icon: <InfoIcon fontSize="large" color="info" />, color: 'info.main', title: 'Confirmation' };
    }
  };

  const { icon, color, title } = getIconDetails();

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="confirmation-dialog-title">
      <DialogTitle id="confirmation-dialog-title" sx={{ display: 'flex', alignItems: 'center', gap: 1, color }}>
        {icon} {title}
      </DialogTitle>
      
      <DialogContent>
        <Typography variant="body1">{message}</Typography>
      </DialogContent>
      
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color={type === 'warning' ? 'error' : 'primary'} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
