import { Avatar, Box, Chip, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import MessageDialog from '../Utils/MessageDialog'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import { format } from 'date-fns';
import useAssetDisposalContext from '../../hooks/UseAssetDisposalContext';
import AssetDetailsTooltip from '../DeploymentRequest/AssetDetailsTooltip';
import AssetLink from './AssetLink';
import AssetUnlink from './AssetUnlink';
import useAuth from '../../hooks/UseAuth';


function AssetDisposalList({requestCode}) {
  const axios = AxiosCustomConfig();
  const {auth} = useAuth();
  const {doneUpdate} = useAssetDisposalContext();
  const [assets, setAssets] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: 'asset_code', headerName: 'Code', flex: 1, minWidth: 100, wrap: true,},
    { field: 'asset_name', headerName: 'Name', width: 100,
      renderCell: (params) => {
        const asset_name = params.value;
        const asset_id = params.row.asset_id;
        return (
          <AssetDetailsTooltip id={asset_id} value={asset_name} />
        );
      }
    },
    { field: 'status_name', headerName: 'Status', flex: 1, minWidth: 100, wrap: true, },
    { field: 'description', headerName: 'Desciption',flex: 1, minWidth: 100, wrap: true  },
    { field: 'created', headerName: 'Created',flex: 1, minWidth: 100,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd');
      }, wrap: true 
    },
    { field: 'modified', headerName: 'Modified',flex: 1, minWidth: 100,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd');
      }, wrap: true 
    },
  ];
  const getAssets = async (ignore) => {
    try {
      setLoading(true);
      const options = {
        'method': 'POST',
        'url' : '/AssetsDisposal/getAssetDisposalList.json',
        'data' : {request_code : requestCode}
      }
      const response = await axios(options);
      const data = response.data;
      if(!ignore) {
        setAssets(data)
        setLoading(false);
      }
      
    } catch (error) {
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    } finally {
      if(!ignore) {
        setLoading(false);
      }
      
    }
  }

  useEffect(() => {
    let ignore = false
    getAssets(ignore)
    return ()=>{ignore=true} 
  }, [doneUpdate]);

  return (
    <Box>
    <Box p={1}>
      <Typography>Asset details</Typography>
    </Box>
    
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px'  }}>
      {Number(auth.role) === 2 || Number(auth.role) === 1 || Number(auth.role) === 0  ? (
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <AssetLink requestCode={requestCode} />
          <AssetUnlink requestCode={requestCode} />
        </Box>
      ) : ''}
    </Box>

    <Box sx={{width: '100%', height: 100, display:'table', tableLayout: 'fixed'}} >
    {assets && 
      <DataGrid
        autoHeight
        keepNonExistentRowsSelected
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        loading={loading}
        rows={assets ? assets : []}
        columns={columns}
        getRowHeight={() => 'auto'}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '15px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
        }}
        />
      }
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </Box>
  )
}


export default AssetDisposalList