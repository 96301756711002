import { Autocomplete, Button, CircularProgress, createFilterOptions, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, StyledEngineProvider, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, validateFileExtensions, validateFileSizes } from '../GenericFunctions/GenericFunctions';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import styled from '@emotion/styled';
import { Close, CloudUpload } from '@mui/icons-material';
import useAuth from '../../hooks/UseAuth';
import { RateSelectionModal } from '../Utils/RateSelectionModal';
import { debounce, isEqual } from 'lodash';
import { render } from '@testing-library/react';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function AddRepairRequest({ assetCode, setOnEditOrDelete }) {
  const { auth } = useAuth();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [repairRequests, setRepairRequests] = useState({
    asset: [],
    asset_code: '',
    asset_name: '',
    repairRatesSelected: [],
    repairRates: [],
    image_before_repair: [],
    is_major_repair: '',
    remarks: '',
    warehouse: [],
    warehouse_unique_code: '',
    warehouse_name: '',
  });

  const [openAssets, setOpenAssets] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [assetsLoading, setAssetsLoading] = useState(false);
  const [openService, setOpenService] = useState(false);
  const [repairRatesOptions, setRepairRatesOptions] = useState([]);
  const [serviceLoading, setServiceLoading] = useState(false);
  const [warehouse, setWarehouses] = useState([]);
  const [openWarehouse, setOpenWarehouse] = useState(false);
  const [loadingWarehouse, setLoadingWarehouse] = useState(false);
  const [repairRateSettings, setRepairRateSettings] = useState({
    value: 0,
    function_name: 'repair_rate_settings'
  });
  const [openRateSelection, setOpenRateSelection] = useState(false);
  const [rateSelectionData, setRateSelectionData] = useState(null);
  const multiSelectRef = useRef(null);
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateRequiredFields()) return

    if (repairRequests.repairRates.length === 0) {
      setOpenMessageDialog(true);
      setMessage('All Fields with asterisk (*) are required');
      return false;
    }

    if (repairRequests.warehouse.length === 0) {
      setOpenMessageDialog(true);
      setMessage('All Fields with asterisk (*) are required');
      return false;
    }

    if (repairRequests.image_before_repair.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Please upload atleast 1 image.')
      return
    }

    if (!repairRequests.remarks) {
      setOpenMessageDialog(true);
      setMessage('All Fields with asterisk (*) are required');
      return false;
    }

    const { isValid, invalidFiles } = validateFileSizes(repairRequests.image_before_repair);
    const { isValidExtension, invalidFilesExtension } = validateFileExtensions(repairRequests.image_before_repair);

    if (!isValid && repairRequests.image_before_repair.length > 0) {
      // Collect names of files that exceed the size limit
      const fileNames = invalidFiles.map((file) => file.name).join(', ');
      setOpenMessageDialog(true);
      setMessage(`The following file(s) exceed the 2 MB limit: ${fileNames}. Please upload smaller files.`);
      return false;
    }

    if (!isValidExtension && repairRequests.image_before_repair.length > 0) {
      const invalidFileNames = invalidFilesExtension.map((file) => file.name).join(', ');
      setOpenMessageDialog(true);
      setMessage(`The following file(s) are not image files: ${invalidFileNames}. Please upload valid image files.`);
      return false;
    }

    if (!isValidSpecialCharacter(repairRequests.remarks) && repairRequests.remarks !== '') {
      setOpenMessageDialog(true);
      setMessage('Error! Invalid special character in remarks.');
      return false;
    }

    setShowProgress(true);
    try {
      const formData = new FormData();
      if (repairRequests.image_before_repair.length > 0) {
        repairRequests.image_before_repair.forEach((file, index) => {
          formData.append(`image_before_repair_${index}`, file);
        });
      }

      // Add other fields to formData if needed
      formData.append('asset_code', repairRequests.asset_code);
      formData.append('asset_name', repairRequests.asset_name);
      formData.append('is_major_repair', repairRequests.is_major_repair);
      formData.append('remarks', repairRequests.remarks);
      formData.append('repair_rates', repairRequests.repairRates);
      formData.append('warehouse_unique_code', repairRequests.warehouse_unique_code);
      formData.append('warehouse_name', repairRequests.warehouse_name);

      // ✅ Convert repairRatesSelected into JSON string before appending
      formData.append('repair_rates_selected', JSON.stringify(repairRequests.repairRatesSelected));

      const options = {
        method: 'POST',
        url: '/ShopRepairs/addRepair.json',
        data: formData,
      }

      const response = await axios(options);
      setShowProgress(false);
      if (response.data.is_success) {
        resetForm();
        setOnEditOrDelete((prev) => !prev)
        setOpen(false)
        setOpenMessageDialog(true);
        setMessage(response.data.message)
      } else {
        setShowProgress(false)
        setOpenMessageDialog(true)
        setMessage(response.data.message)
      }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }


  const resetForm = () => {
    setRepairRequests({
      asset: [],
      asset_code: '',
      asset_name: '',
      repairRatesSelected: [],
      repairRates: [],
      image_before_repair: [],
      is_major_repair: '',
      remarks: '',
      warehouse: [],
      warehouse_unique_code: '',
      warehouse_name: '',
    });
  };

  const validateRequiredFields = () => {
    const requiredFields = ['asset_code'];

    for (const field of requiredFields) {
      if (!repairRequests[field]) {
        setOpenMessageDialog(true);
        setMessage('All Fields with asterisk (*) are required');
        return false;
      }
    }

    return true;
  };

  const getAssets = async (ignore) => {
    try {
      setAssetsLoading(true)
      const options = {
        method: 'POST',
        url: '/AssetsRegistry/getAssetsForRepairs.json',
        data: { asset_code: assetCode, warehouse_unique_code: repairRequests.warehouse_unique_code },
      }
      const response = await axios(options);
      if (!ignore) {
        const filteredAssets = response.data.filter(asset => asset.warehouse_branch);
        setAssets(filteredAssets);
      }
      setAssetsLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getRepairRates = async (ignore) => {
    try {
      setServiceLoading(true)
      const options = {
        method: 'POST',
        url: '/RepairRates/getRepairRatesForAutoComplete.json',
      }
      const response = await axios(options);
      if (!ignore) {
        setRepairRatesOptions(response.data);
      }
      setServiceLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false;

    if (!openAssets) {
      return undefined;
    }

    getAssets(ignore);

    return () => {
      ignore = true;
    };

  }, [openAssets]);

  useEffect(() => {
    let ignore = false;

    if (!openService) return undefined
    getRepairRates(ignore)

    return () => {
      ignore = true
    };
  }, [openService]);


  const hanldeAssetsChange = (e, selectValue) => {
    if (selectValue) {
      return setRepairRequests(
        (prevAccount) => ({ ...prevAccount, asset: selectValue, asset_code: selectValue.asset_code, asset_name: selectValue.asset_name })
      )
    }

    setRepairRequests((prevAccount) => ({ ...prevAccount, asset: [] }))
  }

  const handleRepairRatesChange = async (e, selectValue) => {
    if (!selectValue) return;
  
    const previousSelected = repairRequests.repairRatesSelected || [];
    const repairRates = selectValue.map((item) => item.repair_rate);
  
    // Update State Immediately with Selected Items
    setRepairRequests((prev) => ({
      ...prev,
      repairRatesSelected: selectValue,
      repairRates: repairRates,
    }));
  
    if (repairRateSettings.value === 0) return;
  
    const newSelected = selectValue.filter(
      (item) => !previousSelected.some((prev) => prev.repair_rate === item.repair_rate)
    );
  
    if (newSelected.length === 0) return;
  
    let selectedItems = [...previousSelected];
  
    for (const item of newSelected) {
      try {
        const selectedRate = await new Promise((resolve, reject) => {
          setOpenRateSelection(true);
          setRateSelectionData({
            currentRate: item.price,
            oldRate: item.old_price,
            onConfirm: resolve,
            onCancel: reject, // Reject will handle the cancel case
          });
        });
  
        selectedItems.push({
          repair_rate: item.repair_rate,
          selected_rate: selectedRate,
        });
  
      } catch (error) {
        // Remove the last selected item and refresh `Autocomplete`
        const filteredSelected = selectValue.filter((s) => s.repair_rate !== item.repair_rate);
        
        setRepairRequests((prev) => ({
          ...prev,
          repairRatesSelected: filteredSelected,
        }));

        return;
      }
    }
  
    const uniqueSelected = selectedItems.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.repair_rate === value.repair_rate)
    );
  
    setRepairRequests((prev) => ({
      ...prev,
      repairRatesSelected: uniqueSelected,
    }));
  };

  const hanldeMajorRepair = (e) => {
    setRepairRequests((prevAccount) => ({ ...prevAccount, is_major_repair: e.target.value }))
  }

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setRepairRequests((prevAccount) => ({ ...prevAccount, image_before_repair: [...prevAccount.image_before_repair, ...files] }))
  };

  const handleWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      setRepairRequests((prevData) => ({ ...prevData, warehouse: selectValue, warehouse_unique_code: selectValue.warehouse_unique_code, warehouse_name: selectValue.warehouse_name }))
    }
  }

  const removeImage = (indexToRemove) => {
    // Create a new array excluding the image at the specified index
    const updatedImages = repairRequests.image_before_repair.filter((_, index) => index !== indexToRemove);

    // Update the state with the new array of images
    setRepairRequests(prevState => ({
      ...prevState,
      image_before_repair: updatedImages
    }));
  };


  const getWarehouses = async (ignore) => {
    setLoadingWarehouse(true)
    try {
      const options = {
        method: 'POST',
        url: '/Warehouses/getWarehousesForAutocomplete.json',
      }

      const response = await axios(options);

      if (!ignore) {
        setWarehouses(response.data);
      }
      setLoadingWarehouse(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false;

    if (!openWarehouse) {
      return undefined
    }

    getWarehouses(ignore);

    return () => {
      ignore = true;
    }
  }, [openWarehouse]);

  const getRepairRateSettingsSelection = async (ignore) => {
    try {
      const options = {
        method: 'POST',
        url: '/GeneralSettings/getRepairRateSettings.json',
        data: repairRateSettings
      }
      const response = await axios(options);
      if (!ignore) {
        setRepairRateSettings(response.data);
      }

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false;

    if (!openService) return undefined
    getRepairRateSettingsSelection(ignore)

    return () => {
      ignore = true
    };
  }, [openService]);

  return (
    <div>
      <Button variant="contained" size="small" onClick={handleOpen}>Create Request</Button>
      <Dialog
        open={open}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle>Add New Repair request</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} pt={1}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  open={openWarehouse}
                  onOpen={() => { setOpenWarehouse(true); }}
                  onClose={() => { setOpenWarehouse(false); }}
                  onChange={handleWarehouseChange}
                  options={warehouse}
                  loading={loadingWarehouse}
                  value={warehouse.assets_code}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Centigen Branch*"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingWarehouse ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  id="combo-box-demo"
                  open={openAssets}
                  onOpen={() => { setOpenAssets(true); }}
                  onClose={() => { setOpenAssets(false); }}
                  loading={assetsLoading}
                  onChange={hanldeAssetsChange}
                  options={assets.sort((a, b) => -b.warehouse_branch.localeCompare(a.warehouse_branch))}
                  value={assets.label}
                  noOptionsText={'No Available Data'}
                  groupBy={(option) => option.warehouse_branch}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Asset for Evaluation*"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="is-major-repair">Major repair?</InputLabel>
                <Select
                  labelId="is-major-repair"
                  value={repairRequests.is_major_repair}
                  label="Select value"
                  onChange={hanldeMajorRepair}
                >
                  <MenuItem value={0}>No</MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  open={openService}
                  onOpen={() => { setOpenService(true); }}
                  onClose={() => { setOpenService(false); }}
                  options={repairRatesOptions}
                  value={repairRequests.repairRatesSelected}
                  onChange={handleRepairRatesChange}
                  getOptionLabel={(option) => option.repair_rate || ""}
                  isOptionEqualToValue={(option, value) => option.repair_rate === value.repair_rate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Service*"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {serviceLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} mb={2}>
              <Typography mt={1}>Photo before repair:</Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUpload />}
              >
                Upload images
                <VisuallyHiddenInput
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </Button>
              {repairRequests.image_before_repair.length > 0 && (
                <>
                  <Typography mt={2}>Selected Image:</Typography>
                  <ul>
                    {repairRequests.image_before_repair.map((file, index) => (
                      <li key={index}>
                        {file.name}
                        <IconButton color='error' aria-label="remove" onClick={() => removeImage(index)}>
                          <Close />
                        </IconButton>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography>Remarks*:</Typography>
              <Textarea
                aria-label="minimum height"
                minRows={3}
                placeholder="Input Remarks*"
                onChange={(e) => setRepairRequests((prevAccount) => ({ ...prevAccount, remarks: e.target.value }))}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ paddingLeft: 3, paddingRight: 3, marginBottom: 2 }}>
          <FormControl fullWidth>
            <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      {openRateSelection && rateSelectionData && (
        <RateSelectionModal
          open={openRateSelection}
          onClose={() => {
            rateSelectionData.onCancel(); // Reject on cancel
            setOpenRateSelection(false);
          }}
          currentRate={rateSelectionData.currentRate}
          oldRate={rateSelectionData.oldRate}
          onConfirm={(selectedRate) => {
            rateSelectionData.onConfirm(selectedRate);
            setOpenRateSelection(false);
          }}
        />
      )}


      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..." />
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose={() => setOpenMessageDialog(false)}
          message={message}
        />
      </StyledEngineProvider>

    </div>
  )
}

export default AddRepairRequest