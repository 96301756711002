import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import { AccountTree, Apps,BuildCircle,Business, DesignServices, Grain, Factory, GroupAdd, Home, LocalShipping, Logout, MonetizationOn, PeopleAlt, RvHookup, Settings, VerifiedUser, Warehouse, WorkHistory, DeleteSweep, FolderShared, Construction } from '@mui/icons-material';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import useAuth from '../hooks/UseAuth';
import { Avatar, Badge, Collapse, Grid, ListItemButton } from '@mui/material';
import NotificationsUtil from './Utils/NotificationsUtil';
import NavigationMenu from './NavigationMenu';
import routesData from './Routes.json';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const iconMap = {
  Home, Apps, AccountTree, Factory, LocalShipping,
  MonetizationOn, BuildCircle, Warehouse, GroupAdd,
  Business, PeopleAlt, RvHookup, DesignServices,
  WorkHistory, Settings, Logout, VerifiedUser, HomeRepairServiceIcon, 
  Grain, DeleteSweep, FolderShared, Construction
};

export default function MainDrawer({userRole, setIsLogin}) {
  const {auth} = useAuth();
  const theme = useTheme();
  const isTabletAndUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState('Asset Trakker');
  const [expanded, setExpanded] = useState(false);
  
  const handleDrawerOpen = () => {
    setOpen(prev => !prev);
  };

  const handleToggle = () => {
    setExpanded((prev) => !prev);
  };

  useEffect(() => {
    setOpen(isTabletAndUp);
    const { pathname } = location;

    // Function to recursively find the text for the given path
    const findTitle = (items, path) => {
      for (let item of items) {
        if (item.path === path) {
          return item.text;
        }
        if (item.subItems) {
          const subTitle = findTitle(item.subItems, path);
          if (subTitle) return subTitle;
        }
      }
      return null;
    };

    // Find the title for the current pathname
    const newPageTitle = findTitle(routesData, pathname) || 'Asset Trakker';

    // Update the pageTitle state
    setPageTitle(newPageTitle);
  }, [isTabletAndUp, location]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
          {pageTitle}
        </Typography>
        <NotificationsUtil />
        </Toolbar>
        
      </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 1}}>
          <ListItemButton onClick={handleToggle} sx={{ padding: 0 }}>
              <Grid container direction="column" alignItems="center">
                <Avatar
                  src="/broken-image.jpg"
                  sx={{ width: 20, height: 20, mb: 1 }}
                />
                <Typography variant="subtitle1" sx={{ maxWidth: drawerWidth - 40, fontSize: '12px' }}>
                  {auth.username}
                </Typography>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <Typography variant="subtitle1" sx={{ maxWidth: drawerWidth - 40, fontSize: '12px' }}>
                    Full name: {auth.fullName}
                  </Typography>
                  <Typography variant="body2" sx={{ maxWidth: drawerWidth - 40, fontSize: '10px', color: 'text.secondary' }}>
                    Company ID: {auth.companyId}
                  </Typography>
                </Collapse>
              </Grid>
            </ListItemButton>
          </DrawerHeader>
          <Divider />
          <NavigationMenu routes={routesData} iconMap={iconMap}/>
        </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
}
