import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';


function EditRepairRate({repairRateData, setOnEditOrDelete}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({
    repair_rate: '',
    price: '',
    id: '',
    repair_type_name: '',
    business_unit: '',
    repair_types: null,
    old_price: '',
    rate_modified: '',
    previous_price: ''
  });
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [repairTypes, setRepairTypes] = useState([]);
  const [openRepairTypes, setOpenRepairTypes] = useState(false);
  const [loadingRepairTypes, setLoadingRepairTypes] = useState(false);
  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    const { repair_rate, id, price, repair_type, business_unit, rate_modified, old_price} = repairRateData;
    
    setEditData((prevData) => ({ 
      ...prevData, 
      repair_rate: repair_rate || prevData.repair_rate, 
      price: price || prevData.price, 
      id: id || prevData.id,
      repair_types: {'label' : repair_type, value: repair_type},
      repair_type_name: repair_type || prevData.repair_type,
      business_unit: business_unit || prevData.business_unit,
      rate_modified: rate_modified || prevData.rate_modified,
      old_price: old_price || prevData.old_price,
      previous_price: price
    }))
  };

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const resetForm = () => {
    setEditData({
      repair_rate: '',
      price: '',
      id: '',
      repair_type_name: '',
      business_unit: '',
      old_price: '',
      rate_modified: '',
      repair_types: [],
      previous_price: ''
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateRequiredFields()) return

    if (!isValidSpecialCharacter(editData.repair_rate)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Name.')
        return
    }

    if (Number(editData.price) <= 0) {
      setOpenMessageDialog(true);
      setMessage('Invalid price. Price should be greated than or equal to one.')
      return
    }

    if (!isValidSpecialCharacter(editData.business_unit)) {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Business unit.')
      return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/RepairRates/updateRepairRate.json',
              data: objectToFormData(editData),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          setOpenMessageDialog(true);
          setMessage(response.data.message)
          resetForm();
          setOpen(false)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}.`);
    }
  }

  const validateRequiredFields = () => {
    const requiredFields = ['repair_rate', 'price', 'repair_type_name'];

    for (const field of requiredFields) {
      if (!editData[field]) {
        setOpenMessageDialog(true);
        setMessage('All Fields with asterisk (*) are required');
        return false;
      }
    }

    return true;
  };

  const getRepairTypes = async (active) => {
    try {
      setLoadingRepairTypes(true)
      const options = {
          method: 'POST',
          url: '/RepairTypes/getRepairTypesForAutocomplete.json',
      }
      const response = await axios(options);
      if (active) {
        setRepairTypes(response.data);
      }
      setLoadingRepairTypes(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const handleRepairTypeChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevAccount) => ({ ...prevAccount, repair_types: selectValue, repair_type_name: selectValue.repair_type_name}))
    }
  }

  useEffect(() => {
    let active = true;

    if (!openRepairTypes) {
      return undefined;
    }

    getRepairTypes(active);

    return () => {
      active = false;
    };

  }, [openRepairTypes]);
  
  useEffect(() => {
    if (!openRepairTypes) {
      setRepairTypes([]);
    }
  }, [openRepairTypes]);

  return (
    <div>
      <Button 
        variant="outlined" 
        size="small" 
        sx={{color: theme.palette.secondary.dark, marginRight: '10px'}} 
        onClick={(e) => {handleOpen(e)}}>
      Edit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle>Edit Repair rate</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label="Repair rate*"
                fullWidth
                variant="standard"
                onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, repair_rate: e.target.value }))}
                value={editData.repair_rate}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="price"
                label="Price*"
                fullWidth
                variant="standard"
                onChange={(e) => {
                    const input = e.target.value;
                    // Regular expression to allow numbers with optional decimal point
                    if (/^\d*\.?\d*$/.test(input)) {
                        // Check if the numeric value is within the range [0, 1000000]
                        if (!isNaN(input) && input >= 0 && input <= 1000000) {
                          setEditData((prevAccount) => ({ ...prevAccount, price: input }));
                        }
                    }
                }}
                value={editData.price}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled
                name="old_price"
                label="Old Price"
                fullWidth
                variant="standard"
                value={editData.old_price}
              />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                      id="combo-box-demo"
                      open={openRepairTypes}
                      onOpen={() => {setOpenRepairTypes(true);}}
                      onClose={() => {setOpenRepairTypes(false);}}
                      onChange={handleRepairTypeChange}
                      options={repairTypes}
                      value={editData.repair_types}
                      loading={loadingRepairTypes}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Repair Type*"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingRepairTypes ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="business_unit"
                  label="Business unit"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, business_unit: e.target.value }))}
                  value={editData.business_unit}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
            setOnEditOrDelete((prev) => !prev)
          }}
          message={message}
        />
      </StyledEngineProvider>
    </div>
  )
}

export default EditRepairRate